// import React from "react";
// import "./styles.css";
// import middleImg from "./assets/midImg.png";

// function Donate() {
//   return (
//     <main className="donate-page d-flex flex-column justify-content-center align-items-center">
//       <div className="page-content d-flex flex-column justify-content-center align-items-center">
//         <section className="container donate-page-section mt-4 d-flex flex-column align-items-center">
//           <h3 className="section-head mt-4 mb-3">Donate to NavGurukul</h3>
         
          // <div className="d-flex account-details justify-content-center align-items-center">
          //   <div className="d-flex flex-column text-left fw-bold">
          //     <span>UPI ID</span>
          //     <span>Name</span>
          //     <span>Account Number</span>
          //     <span>IFSC Code</span>
          //   </div>
          //   <div className=" d-flex section-para flex-column text-left pl-2 pl-md-4 ">
          //     <span>navgurukulf@kotak</span>
          //     <span>Navgurukul Foundation for Social Welfare</span>
          //     <span>0512251778</span>
          //     <span>KKBK0004260</span>
          //   </div>
          // </div>
//           <hr className="w-30 partition-line mb-0" style={{ color: "#cdcdcd" }}></hr>

//           <div className="donatation-payment mt-md-3 pt-3 mt-0 d-flex justify-content-center align-items-center w-100">
//             <p className="w-100 fw-bold">
//               You can also directly pay (2% charge but quick) using our payment
//               gateway
//             </p>
//           </div>
//           <button
//             type="button"
//             class="donate-page-button regular-btn"
//             onClick={(e) => {
//               e.preventDefault();
//               window.open("https://www.instamojo.com/@navgurukul/", "_blank");
//             }}
//           >
//             Donate to Navgurukul
//           </button>
//           <div className="donate-content d-flex justify-content-center align-items-center w-100">
//             <p className="w-75 mt-3 mt-md-4 pt-3">
//               You may also pledge a <b> monthly donation </b> to us. Any pledged
//               amount is a huge support for us. Just having you by our side fills
//               us with gratitude & an energy to do better.
//             </p>
//           </div>
//           <div className="container  donate-button-container">
//             <div className="row">
//               <div class="col-md col-sm-12 px-0 pxmd-3">
//                 <button
//                   type="button"
//                   class="btn btn-primary donate-button dashed-btn"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     window.open(
//                       "https://app.lotuspay.com/merchant/plan/AL0092PDFN2KYC",
//                       "_blank"
//                     );
//                   }}
//                 >
//                   ₹ 100 per month
//                 </button>
//                 <button
//                   type="button"
//                   class="btn btn-primary donate-button dashed-btn"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     window.open(
//                       "https://app.lotuspay.com/merchant/plan/AL00681BQGXHMI",
//                       "_blank"
//                     );
//                   }}
//                 >
//                   ₹ 500 per month
//                 </button>
//                 <button
//                   type="button"
//                   class="btn btn-primary donate-button dashed-btn"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     window.open(
//                       "https://app.lotuspay.com/merchant/plan/AL0074EBKKW2EI",
//                       "_blank"
//                     );
//                   }}
//                 >
//                   ₹ 1000 per month
//                 </button>
//                 <button
//                   type="button"
//                   class="btn btn-primary  donate-button dashed-btn"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     window.open(
//                       "https://app.lotuspay.com/merchant/plan/AL0022DKW1KZGD",
//                       "_blank"
//                     );
//                   }}
//                 >
//                   ₹ 2500 per month
//                 </button>
//                 <button
//                   type="button"
//                   class="btn btn-primary  donate-button dashed-btn"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     window.open(
//                       "https://app.lotuspay.com/merchant/plan/AL0042NG8XJV3P",
//                       "_blank"
//                     );
//                   }}
//                 >
//                   ₹ 5000 per month
//                 </button>
//                 <button
//                   type="button"
//                   class="btn btn-primary  donate-button dashed-btn"
//                   onClick={(e) => {
//                     e.preventDefault();
//                     window.open(
//                       "https://app.lotuspay.com/merchant/plan/AL0012PAC9ZPYV",
//                       "_blank"
//                     );
//                   }}
//                 >
//                   ₹ 10000 per month
//                 </button>
//               </div>
//             </div>
//           </div>
//           <div className="about-donation w-100 d-flex flex-column justify-content-center align-items-center">
//             <p className="w-50 mb-0">
//               Any amount donated to NavGurukul will be eligible
//               for tax exemption under 80G.
//             </p>
//           </div>
//         </section>
//         {/* <section className="d-flex flex-column donate-page-section donate-page-image-section">
//           <h3 className="mt-5 mb-3 section-head">
//             Your Donations Create Impact
//           </h3>
//           <hr className="heading-hr align-self-center" />
//           <img className="middleImg" src={middleImg}></img>
//           <div className="donation-impact-data d-flex justify-content-around my-5">
//             <span>
//               <span className="section-head">160+</span>
//               <p className="">Software Development Jobs</p>
//             </span>
//             <span>
//               <span className="section-head">4000+</span>
//               <p className="">Online Students</p>
//             </span>
//           </div>
//           <div className="donation-impact-data donation-impact-data-2 d-flex justify-content-around mt-5 ">
//             <span>
//               <span className="section-head">5</span>
//               <p className="">States across India</p>
//             </span>
//           </div>
          
//         </section> */}
//         <section className="container donate-page-section csr-queries ">
//           <h4 className="section-head mb-3">
//             Are you a corporation and taken an interest in what we do?
//           </h4>
//           {/* <button type="button" class="btn btn-primary button-colour"> */}
//           <button
//             type="button"
//             class=" donate-page-button regular-btn mt-0 contact-button mb-3"
//             onClick={(e) => {
//               e.preventDefault();
//               window.open(
//                 "https://docs.google.com/forms/d/e/1FAIpQLSdehRy3gDHgkj4bC-0AehiCNrk1_WgP39Zm7-l0YXowREupdw/viewform",
//                 "_blank"
//               );
//             }}
//           >
//             Contact Us for CSR Enquiries
//           </button>
//         </section>
//       </div>
//     </main>
//   );
// }

// export default Donate;

import React, { useState } from 'react';
import './styles.css'
import "../../Global.css";

const Donate = () => {
  const details = [
    { label: 'UPI ID', info: 'navgurukulf@kotak' },
    { label: 'Name', info: 'Navgurukul Foundation for Social Welfare' },
    { label: 'Account Number', info: '0512251778' },
    { label: 'IFSC Code', info: 'KKBK0004260' },
  ];
  const details1 =[
    { label: 'Name', info: 'Navgurukul Foundation for Social Welfare' },
    { label: 'FCRA Account Number', info: '39891190304' },
    { label: 'IFSC Code', info: 'SBIN0000691' },
    { label: 'Swift Code', info: 'SBININBB104' },

  ]
  const [selectedCitizenship, setSelectedCitizenship] = useState('Indian');

  const handleCitizenshipChange = (citizenship) => {
    setSelectedCitizenship(citizenship);
  };

  const [selectedOption, setSelectedOption] = useState('monthly');
  const [selectedAmount, setSelectedAmount] = useState(0);

  const selectOption = (option) => {
    setSelectedOption(option === selectedOption ? null : option);
  };

  const handleButtonClick = (amount, link) => {
    setSelectedAmount(amount);
    window.open(link, '_blank');
  };
  const handleProceedBtnClick = (link) => {
    window.open(link, '_blank');
  
  };

 
  const donationOptions = [
    { amount: 100, link: 'https://app.lotuspay.com/merchant/plan/AL0092PDFN2KYC' },
    { amount: 500, link: 'https://app.lotuspay.com/merchant/plan/AL00681BQGXHMI' },
    { amount: 1000, link: 'https://app.lotuspay.com/merchant/plan/AL0074EBKKW2EI' },
    { amount: 2500, link: 'https://app.lotuspay.com/merchant/plan/AL0022DKW1KZGD' },
    { amount: 5000, link: 'https://app.lotuspay.com/merchant/plan/AL0042NG8XJV3P' },
    { amount: 10000, link: 'https://app.lotuspay.com/merchant/plan/AL0012PAC9ZPYV' },
  ];

  return (
    <main className="container mt-4">
      <h4 className="section-head mt-4 mb-5">
       Your Donation helps us transform underprivileged students into skilled coders
        </h4>
        <p>
          <h5>Why is your donation important? </h5>
          In a situation where young, underprivileged individuals face the prospect of early marriage, cyclic poverty, and restricted access to quality education, Your donation is more than a gift; offering them an alternative path filled with opportunities.
          By supporting their education, you're helping them discover their social agency, develop volition, and become exemplary citizens who can lead independent lives.
          Help us bridge the divide and create a nurturing environment where these young minds can flourish professionally and personally.
        </p>
        <div className=" d-flex flex-column ">
          <h5 className="mb-3 mt-3"> Any amount donated to NavGurukul will be eligible for tax exemption under 80G.</h5>
        </div>

      <form>
        <div className="form-check form-check-inline mr-4 " onClick={() => handleCitizenshipChange('Indian')}>
          <input 
            className="form-check-input mr-2 hand-pointer"
            type="radio"
            name="citizenshipOptions"
            id="indianCitizen"
            value="Indian"
            checked={selectedCitizenship === 'Indian'}
            onChange={() => handleCitizenshipChange('Indian')}
          />
          <label className={`form-check-label hand-pointer ${selectedCitizenship === 'Indian' ? 'bold-text' : ''}`}
           htmlFor="indianCitizen">Indian Citizen</label>
        </div>
        <div className="form-check form-check-inline " onClick={() => handleCitizenshipChange('Foreign')}>
          <input 
            className="form-check-input mr-2 hand-pointer"
            type="radio"
            name="citizenshipOptions"
            id="foreignCitizen"
            value="Foreign"
            checked={selectedCitizenship === 'Foreign'}
            onChange={() => handleCitizenshipChange('Foreign')}
          />
          <label className={`form-check-label hand-pointer ${selectedCitizenship === 'Foreign' ? 'bold-text' : ''}`} htmlFor="foreignCitizen">Foreign Citizen</label>
        </div>
      </form>
      {selectedCitizenship === 'Indian' &&
      <div className="row mt-4 " >
        <div className="col-md-5 p-0">
          <h5 className="section-head mb-4">Donate via Payment Gateway</h5>
          <div className="d-flex">
            <div className="col-md-4 col-sm-6 p-0">
              <div
                onClick={() => selectOption('monthly')}
                className={selectedOption === 'monthly' ? 'section-head mb-4 hand-pointer text-center' : 'section-para  mb-4  hand-pointer text-center'}
                style={
                  selectedOption === 'monthly'
                  ? {
                      borderBottom: "3px solid #f05f40",
                      fontWeight: "bold",
                    }
                  : {}
              }
              >
               <p>Monthly</p> 
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div
                onClick={() => selectOption('oneTime')}
                className={selectedOption === 'oneTime' ? 'section-head  mb-4 hand-pointer text-center' : 'section-para  mb-4  hand-pointer text-center'}
                style={
                  selectedOption === 'oneTime'
                  ? {
                      borderBottom: "3px solid #f05f40",
                      fontWeight: "bold",
                    }
                  : {}
              }
              >
                <p>One Time</p>
              </div>
            </div>
          </div>
          <div>
          <div id="monthlyContent" style={{ display: selectedOption === 'monthly' ? 'block' : 'none' }} >
            <div className="row mb-4  ">
            {donationOptions.map(({ amount, link }) => (
                    <div key={amount} className="col-sm-6 col-md-4 p-0">
                      <button
                        type="button"
                        className={selectedAmount === amount ?" btn section-para regular-btn ": "btn custom-btn mb-4 "}
                        style={{ width: '80%', height: '60px', marginRight: '32px' }}
                        onClick={() => handleButtonClick(amount, link)}
                      >
                        ₹ {amount}
                      </button>
                    </div>
                  ))}
                </div>
                <p className="text mt-3">
                  Please note: A 2% charge will be levied for using our payment gateway
                </p>   
            
              </div>
            </div>
            <div id="oneTimeContent" style={{ display: selectedOption === 'oneTime' ? 'block' : 'none' }}>
              <p className="mb-4">Quick and easy way to support us</p>
              <button
                type="button"
                className="btn section-para regular-btn w-100"
                style={{ height: '50px' }}  
                onClick={() => handleProceedBtnClick("https://www.instamojo.com/@navgurukul/")}
              >
                Proceed to Donate
              </button>
              <p className="text mt-3">
                Please note: A 2% charge will be levied for using our payment gateway
              </p>  
          </div>
          </div>
        
          <div className="col-md-4 leftText p-0" >
            <h5 className="section-head mb-4  ">Donate via Bank Transfer</h5>
            {details.map((item, index) => (
          <div key={index} className="row ">
            <div className="col-md-12 p-0 ">
              <p className=' info fw-bold '>{item.label}</p>
              <div className=" section-para ">
                <p className='info'>{item.info}</p>
              </div>
            </div>
          </div>
        ))}
        </div>   
      </div>
      }
      {selectedCitizenship === 'Foreign' && (
        <>
        <h5 className=" mb-4 mt-4">Donate via Bank Transfer</h5>
        {details1.map((item, index) => (
          <div key={index} className="row">
            <div >
              <p className=' info fw-bold '>{item.label}</p>
              <div >
                <p className='info'>{item.info}</p>
              </div>
            </div>
          </div>
        ))}
    
        <p className='mt-3 mb-3'>Please share the following details with us at <a href='mailto:donate@navgurukul.org' style={{ color: '#EB5F42' }} className='fw-bold'>donate@navgurukul.org</a> after making the donation. These details are mandatory to be shared to process the transaction:</p>
        <div className="ml-5">
          <ul>
            <li><p>Your name</p></li>
            <li><p>Contact Number</p></li>
            <li><p>Email Address</p></li>
            <li><p>Transaction ID and amount</p></li>
            <li><p>Address if available</p></li>
          </ul>
        </div>
        <p className='mt3 mb-3'>Please connect with us at <a href='mailto:donate@navgurukul.org' style={{ color: '#EB5F42' }} className='fw-bold'>donate@navgurukul.org</a> if you have any questions before making the donation</p>
        </>
      )}
      
      <hr className="w-100 partition-line mb-3" style={{ color: "#cdcdcd" }}></hr>
      <div className='mt-3'>
           <h3 className="section-head mb-3">
              Are you a corporation and taken an interest in what we do?
          </h3>
          <button
             type="button"
            class="btn section-para regular-btn w-40 mb-6 "
             onClick={(e) => {
               e.preventDefault();
               window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSdehRy3gDHgkj4bC-0AehiCNrk1_WgP39Zm7-l0YXowREupdw/viewform",
                 "_blank"
              );
             }}
          >
            Contact Us for CSR Enquiries
          </button>
        </div>
    </main>
  );
};

export default Donate;


