export const data = [
    {
        id: 1,
        video: "https://www.youtube.com/embed/DELF_PyG_E4",
        name: "Annu Bharti’s Story",
    }, 
    {
        id: 2,
        video: "https://www.youtube.com/embed/XJ6hcfkSDkw",
        name: "Rani Pandit’s Story"
    }, 
    {
        id: 3,
        video:"https://www.youtube.com/embed/o3mXHf7LWiw",
        name: "Kajal Agarwal’s Story"
    }
];
